import React from 'react';
import { Formik } from 'formik';
import { Space } from 'antd';
import { Form, Input, SubmitButton } from 'formik-antd';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { FormCard } from './UI';

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

export const ForgotPasswordForm: React.FC = () => {
    return (
        <FormCard title="Forgot Password?">
            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={async (data, { resetForm }) => {
                    // triggerLogin(data);
                    resetForm();
                }}
                validationSchema={ForgotPasswordSchema}
            >
                {() => (
                    <Form>
                        <Space size="large" direction="vertical" style={{ width: '100%' }}>
                            <p>Confirm your details and we’ll send you the instructions.</p>
                            <Form.Item name="email">
                                Email
                                <Input name="email" placeholder="email" />
                            </Form.Item>

                            <SubmitButton loading={false} block>
                                Rest Password
                            </SubmitButton>
                            <Link to="..">Take Me Back to Login</Link>
                        </Space>
                    </Form>
                )}
            </Formik>
        </FormCard>
    );
};
