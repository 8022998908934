import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UsersState {
    me: IUser | null;
}

export enum UserRole {
    Anonymous = 'anonymous',
    User = 'user',
    Admin = 'admin',
    Superadmin = 'superadmin',
}

export interface IDataUsage {
    uploadMB: number;
    downloadMB: number;
}

export interface IUser {
    id: string;
    email: string;
    username: string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    organizationId: string;

    role: UserRole;
    verified: boolean;
    disabled: boolean;

    street?: string;
    state?: string;
    city?: string;
    zipCode?: string;
    phone?: string;
    fax?: string;
    mobile?: string;

    vehicleId?: string;
    devices?: string[];

    dataUsage?: IDataUsage;
}

const initialState: UsersState = {
    me: null,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        updateMe: (state, action: PayloadAction<IUser>) => {
            state.me = action.payload;
        },
    },
});

export const { updateMe } = usersSlice.actions;

export const selectMe = (state: RootState) => state.users.me;

export default usersSlice.reducer;
