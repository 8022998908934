import { AlertOutlined, LogoutOutlined, TeamOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { CallWidget } from './components/call/CallWidget';
import { NotificationsWidget } from './components/notifications/NotificationsWidget';
import { SidebarHeader, StyledAppLayoutContent, StyledAppLayoutMenu, StyledAppLayoutSider } from './components/UI';
import { updateAccessToken } from './store/auth/auth.slice';
import { useAppDispatch } from './store/hooks';
import { useGetMeQuery } from './store/users/user-api.slice';

const App: React.FC = () => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useAppDispatch();
    const logout = useCallback(() => {
        dispatch(updateAccessToken(null));
    }, []);

    useGetMeQuery({});

    useEffect(() => {
        Notification.requestPermission();
    }, []);

    return (
        <Layout>
            <StyledAppLayoutSider theme="light" collapsed={collapsed} onCollapse={setCollapsed} collapsible>
                <SidebarHeader />
                <StyledAppLayoutMenu
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    items={[
                        {
                            type: 'group',
                            children: [
                                {
                                    key: '/drivers',
                                    icon: <TeamOutlined />,
                                    label: <Link to="drivers">CabChat</Link>,
                                },
                                {
                                    key: '/support',
                                    icon: <AlertOutlined />,
                                    label: <Link to="support">CabSupport</Link>,
                                },
                            ],
                        },
                        {
                            type: 'group',
                            children: [
                                {
                                    key: 'logout',
                                    label: 'Logout',
                                    icon: <LogoutOutlined />,
                                    onClick: logout,
                                },
                            ],
                        },
                    ]}
                />
            </StyledAppLayoutSider>
            <StyledAppLayoutContent>
                <Outlet />
            </StyledAppLayoutContent>
            <NotificationsWidget />
            <CallWidget />
        </Layout>
    );
};

export default App;
