import React, { FC, useState, useEffect } from 'react';
import Video, { RemoteParticipant, Room } from 'twilio-video';

import { ParticipantWrapper, VideoShadow, VideoWrapper, Wrapper } from './TwilioVideoChat.styles';
import { TwilioParticipant } from '../TwilioParticipant/TwilioParticipant';
import { TwilioModal, ParticipantVideoType } from '../../models/call.models';
import { TwilioActions } from '../TwilioActions/TwilioActions';
import { TwilioTimer } from '../TwilioTimer/TwilioTimer';

interface TwilioVideoChatProps extends Pick<TwilioModal, 'timerStartedAt'> {
    onLogout: () => void;
    room: Room | null;
    participants: RemoteParticipant[];
    // toggleChat: () => void;
    // isMessageChatOpen: boolean | undefined;
    isFullscreen: boolean | undefined;
    toggleFullscreen: () => void;
    // hasUnreadMessages: boolean | undefined;
    defaultCamera?: boolean;
    defaultMic?: boolean;
}

export const TwilioVideoChat: FC<TwilioVideoChatProps> = ({
    onLogout,
    room,
    participants,
    isFullscreen,
    toggleFullscreen,
    timerStartedAt,
    // isMessageChatOpen,
    // hasUnreadMessages,
    // toggleChat,
    defaultCamera = true,
    defaultMic = true,
}) => {
    const [isCamera, setIsCamera] = useState<boolean>(defaultCamera);
    const [isAudio, setIsAudio] = useState<boolean>(defaultMic);
    useEffect(() => {
        if (!defaultCamera) {
            if (room) {
                room.localParticipant.videoTracks.forEach((publication) => {
                    publication.track.disable();
                    publication.track.stop();
                    publication.unpublish();
                });
            }
        }
        if (!defaultMic) {
            if (room) {
                room.localParticipant.audioTracks.forEach((publication) => {
                    publication.track.disable();
                });
            }
        }
    }, []);

    const disableCamera = () => {
        if (room) {
            room.localParticipant.videoTracks.forEach((publication) => {
                publication.track.disable();
                publication.track.stop();
                publication.unpublish();
            });
            setIsCamera(false);
        }
    };

    const enableCamera = async () => {
        if (room) {
            const track = await Video.createLocalVideoTrack();
            await room.localParticipant.publishTrack(track);
            setIsCamera(true);
        }
    };

    const toggleCamera = () => (isCamera ? disableCamera() : enableCamera());

    const mute = () => {
        if (room) {
            room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.disable();
            });
            setIsAudio(false);
        }
    };

    const unmute = async () => {
        if (room) {
            room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.enable();
            });
            setIsAudio(true);
        }
    };

    const toggleAudio = () => (isAudio ? mute() : unmute());

    const getVideo = () => {
        if (room) {
            return (
                <>
                    <VideoWrapper>
                        {[...room.participants].length > 0 ? (
                            participants.map(
                                (participant) =>
                                    participant.state === 'connected' && (
                                        <TwilioParticipant
                                            key={participant.sid}
                                            participant={participant}
                                            isMessageChatOpen={false}
                                            participantVideoType={ParticipantVideoType.REMOTE}
                                        />
                                    )
                            )
                        ) : (
                            <TwilioParticipant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                                isMessageChatOpen={false}
                                participantVideoType={ParticipantVideoType.LOCAL}
                            />
                        )}
                        <VideoShadow $isMessageChatOpen={false} />
                    </VideoWrapper>
                    {[...room.participants].length > 0 && (defaultCamera || isCamera) ? (
                        <ParticipantWrapper>
                            <TwilioParticipant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                                isMessageChatOpen={false}
                                participantJoined={participants.length > 0}
                                participantVideoType={ParticipantVideoType.LOCAL_PREVIEW}
                            />
                        </ParticipantWrapper>
                    ) : null}
                </>
            );
        }
    };

    return (
        <Wrapper>
            {getVideo()}
            <TwilioTimer timerStartedAt={timerStartedAt} />
            <TwilioActions
                isFullscreen={isFullscreen}
                toggleFullscreen={toggleFullscreen}
                toggleAudio={toggleAudio}
                isAudio={isAudio}
                toggleCamera={toggleCamera}
                isCamera={isCamera}
                onLogout={onLogout}
                // isMessageChatOpen={isMessageChatOpen}
                // hasUnreadMessages={hasUnreadMessages}
                // toggleChat={toggleChat}
            />
        </Wrapper>
    );
};
