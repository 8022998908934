import styled, { css } from 'styled-components';
import { ParticipantVideoType } from '../../models/call.models';

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
`;

export const StyledVideo = styled.video<{
    $isMessageChatOpen?: boolean;
    $participantJoined?: boolean;
    $participantVideoType?: ParticipantVideoType;
}>`
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
    border-bottom-left-radius: ${({ $isMessageChatOpen }) => ($isMessageChatOpen ? 0 : 8)}px;
    border-top-left-radius: ${({ $isMessageChatOpen }) => ($isMessageChatOpen ? 0 : 8)}px;

    ${({ $participantJoined }) =>
        $participantJoined &&
        css`
            border-radius: 8px;
        `}
    ${({ $participantVideoType }) =>
        ($participantVideoType === ParticipantVideoType.LOCAL ||
            $participantVideoType === ParticipantVideoType.LOCAL_PREVIEW) &&
        css`
            transform: rotateY(180deg);
        `}

    ${({ $participantVideoType }) =>
        $participantVideoType === ParticipantVideoType.LOCAL_PREVIEW &&
        css`
            object-fit: cover;
        `}
`;

export const VideoPlaceholder = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 60px;
        height: 60px;
    }
`;
