import styled from 'styled-components';
import { Typography } from 'antd';

export const Wrapper = styled.div`
    min-width: 60px;
    height: 22px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    gap: 6px;
    padding: 3px 6px 3px 3px;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
`;

export const TimeWrapper = styled(Typography.Text)`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
`;

export const IconWrapper = styled.div`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
`;
