import React, { FC, useCallback, useState } from 'react';

import { selectVideoCall } from '../../store/communication/communication.slice';
import { useAppSelector } from '../../store/hooks';
import { Call } from './Call';
import { VideoCallModal } from './components/UI/CallModal';

export const CallWidget: FC<{}> = () => {
    const videoCall = useAppSelector(selectVideoCall);

    const [isFullscreen, setFullscreen] = useState(false);
    const toggleFullscreen = useCallback(() => {
        if (!isFullscreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
        setFullscreen(!isFullscreen);
    }, [isFullscreen]);

    return (
        <>
            <VideoCallModal
                footer={null}
                open={!!videoCall}
                width={640}
                centered
                maskClosable={false}
                closable={false}
                className={isFullscreen ? 'full-screen' : ''}
            >
                {videoCall?.roomName && (
                    <Call
                        roomName={videoCall?.roomName}
                        defaultCamera={videoCall?.defaultCamera}
                        defaultMic={videoCall?.defaultMic}
                        isFullscreen={isFullscreen}
                        toggleFullscreen={toggleFullscreen}
                        key={videoCall?.roomName}
                    />
                )}
            </VideoCallModal>
        </>
    );
};
