import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledPageLayout = styled(Layout)`
    min-height: 100vh;
    display: flex;
    gap: 16px;
    padding: 24px 24px 24px 24px;
`;

export const StyledPageHeader = styled(Layout.Header)`
    padding: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        margin: 0;
    }
`;

export const StyledPageContent = styled.div`
    padding: 24px;
    background: white;
`;
