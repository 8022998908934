import { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { useAppSelector } from '../../store/hooks';
import { selectAccessToken } from '../../store/auth/auth.slice';

export const useSocketIo = () => {
    const [isConnected, setIsConnected] = useState(false);

    const token = useAppSelector(selectAccessToken);

    useEffect(() => {
        let socket!: Socket;
        if (token) {
            socket = io(process.env.REACT_APP_API_BASE_URL || '', {
                path: '/user-tracking',
                extraHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
            socket.on('connect', () => {
                setIsConnected(true);
            });

            return () => {
                socket.disconnect();
            };
        } else {
            socket?.disconnect();
        }
    }, [token]);

    return { isConnected };
};
