import { useSocketIo } from './useSocketIo';
import { ReactElement } from 'react';

type Props = {
    children: ReactElement;
};

export const UserTracker = ({ children }: Props) => {
    useSocketIo();
    return <>{children}</>;
};
