import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { updateAccessToken } from './auth.slice';

export const authApiSlice = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth` }),
    endpoints: (builder) => ({
        login: builder.mutation<any, { email: string; password: string }>({
            query: ({ email, password }) => ({
                url: 'login',
                method: 'POST',
                body: { email, password },
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
                dispatch(updateAccessToken(data.accessToken));
            },
        }),
        register: builder.mutation<any, { email: string; password: string }>({
            query: ({ email, password }) => ({
                url: 'register',
                method: 'POST',
                body: { email, password },
            }),
        }),
    }),
});

export const { useLoginMutation, useRegisterMutation } = authApiSlice;
