import styled, { css } from 'styled-components';
import Button from '../../../UI/Button/Button';

export const ActionsWrapper = styled.div`
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;

    & > div {
        display: flex;
        align-items: center;

        :first-child {
            flex: 1;
            padding-left: 24px;
            justify-content: space-between;
        }

        :last-child {
            justify-content: space-between;
            flex: 1;
            padding-right: 24px;
        }
    }
`;

export const EndCallButton = styled(Button)`
    width: 56px;
    height: 56px;
    background-color: #ec2e45;
    border-radius: 16px;
    border: none;
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: #d21f35;
    }
`;

export const ToggleButton = styled(Button)<{ $isOff: boolean }>`
    width: 48px;
    height: 48px;
    background-color: ${({ $isOff }) => ($isOff ? '#ec2e45 !important' : '#fff !important')};
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    }
`;

export const ToggleSmallButton = styled(Button)`
    color: white;
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover,
    :active,
    :focus {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    }
`;

export const ToggleChatButton = styled(ToggleSmallButton)<{ $isOff: boolean; $hasUnreadMessages?: boolean }>`
    position: relative;
    margin-left: 16px;
    background-color: ${({ $isOff }) => ($isOff ? 'white !important' : 'black !important')};

    ${({ $hasUnreadMessages }) =>
        $hasUnreadMessages &&
        css`
            &::after {
                content: '';
                display: block;
                position: absolute;
                height: 6px;
                width: 6px;
                top: 9px;
                right: 7px;
                border-radius: 50%;
                background-color: #ec2e45;
            }
        `};
`;

export const ToggleSizeButton = styled(ToggleSmallButton)<{ $isOff: boolean }>`
    margin-right: 16px;
    background-color: ${({ $isOff }) => ($isOff ? 'white !important' : 'black !important')};
`;
