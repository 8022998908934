import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IVideoCall {
    roomName: string;
    defaultCamera?: boolean;
    defaultMic?: boolean;
}

export interface CommunicationState {
    videoCall: IVideoCall | null;
}

const initialState: CommunicationState = {
    videoCall: null,
};

export const communicationSlice = createSlice({
    name: 'communication',
    initialState,
    reducers: {
        updateVideoCall: (state, action: PayloadAction<IVideoCall | null>) => {
            state.videoCall = action.payload;
        },
    },
});

export const { updateVideoCall } = communicationSlice.actions;

export const selectVideoCall = (state: RootState) => state.communication.videoCall;

export default communicationSlice.reducer;
