import { TablePaginationConfig } from 'antd';
import { useEffect, useState } from 'react';

import { StyledPageContent, StyledPageHeader, StyledPageLayout } from '../../components/UI';
import { useLazySearchUsersQuery } from '../../store/users/users-api.slice';
import { DriversForm, IFormData } from './components/DriversForm';
import { DriversTable } from './components/DriversTable';
import { AppNames } from '../../types/AppNames';
import { useAppName } from '../../hooks/useAppName';

const DRIVERS_REFRESH_RATE_MS = 30 * 1000;

export function Drivers() {
    useAppName(AppNames.CabChat);

    const [search, setSearch] = useState('');
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 0,
        pageSize: 10,
    });
    const [searchUsersTrigger, { data: searchUsersData, isLoading, isFetching }] = useLazySearchUsersQuery();

    const handleSearchChange = (input: IFormData) => {
        setSearch(input.search);
        setPagination((state) => ({ ...state, current: 0 }));
    };

    const handlePaginationChange = (input: TablePaginationConfig) => {
        setPagination(input);
    };

    useEffect(() => {
        searchUsersTrigger({ currentPage: pagination.current, pageSize: pagination.pageSize, search });

        const handler = setInterval(
            () => searchUsersTrigger({ currentPage: pagination.current, pageSize: pagination.pageSize, search }),
            DRIVERS_REFRESH_RATE_MS
        );

        return () => clearInterval(handler);
    }, [pagination, search]);

    return (
        <StyledPageLayout>
            <StyledPageHeader>
                <h1>CabChat</h1>
                <DriversForm onChange={handleSearchChange} />
            </StyledPageHeader>
            <StyledPageContent>
                <DriversTable
                    data={searchUsersData?.users || []}
                    dataLimitGB={searchUsersData?.dataLimitGB}
                    isLoading={isLoading || isFetching}
                    handleTableChange={handlePaginationChange}
                    pagination={{ ...pagination, total: searchUsersData?.total }}
                />
            </StyledPageContent>
        </StyledPageLayout>
    );
}
