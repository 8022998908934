import { FunctionComponent, MouseEvent, ReactNode } from 'react';
import { Button } from 'antd';

export interface ButtonElementProps {
    isPending?: boolean;
    type?: 'text' | 'link' | 'ghost' | 'default' | 'primary' | 'dashed';
    onClick?: (param: MouseEvent | undefined) => void;
    block?: boolean;
    htmlType?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    icon?: ReactNode;
    className?: string;
    dataTestId?: string;
    children?: ReactNode;
    href?: string;
}

const ButtonElement: FunctionComponent<ButtonElementProps> = ({
    isPending,
    children,
    onClick,
    type = 'default',
    block,
    disabled,
    htmlType,
    icon,
    className,
    dataTestId,
    href,
}) => {
    return (
        <Button
            href={href}
            type={type}
            loading={isPending}
            block={block}
            disabled={disabled}
            onClick={onClick}
            htmlType={htmlType}
            icon={icon}
            className={className}
            data-test-id={dataTestId}
        >
            {children}
        </Button>
    );
};

export default ButtonElement;
