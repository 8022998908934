import { TablePaginationConfig } from 'antd';
import { useEffect, useState } from 'react';

import { StyledPageContent, StyledPageHeader, StyledPageLayout } from '../../components/UI';
import { useLazySearchSupportRequestsQuery } from '../../store/support/support-api.slice';
import { ISupportFormData, SupportForm } from './components/SupportForm';
import { SupportRequestsTable } from './components/SupportRequestsTable';
import { useAppName } from '../../hooks/useAppName';
import { AppNames } from '../../types/AppNames';

export function Support() {
    useAppName(AppNames.CabSupport);
    const [loading, setLoading] = useState(false);
    const [searchAndPaginatioCache, setSearchAndPaginationCache] = useState<{
        search: string;
        pagination: TablePaginationConfig;
    }>({
        search: '',
        pagination: {
            current: -1,
            pageSize: 10,
        },
    });
    const [search, setSearch] = useState('');
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 0,
        pageSize: 10,
    });
    const [searchSupportRequestsTrigger, { data: supportRequestsData, isLoading, isFetching }] =
        useLazySearchSupportRequestsQuery({
            pollingInterval: 5000,
        });

    const handleSearchChange = (input: ISupportFormData) => {
        setSearch(input.search);
        setPagination((state) => ({ ...state, current: 0 }));
    };

    const handlePaginationChange = (pagination: TablePaginationConfig) => {
        setPagination(pagination);
    };

    useEffect(() => {
        searchSupportRequestsTrigger({ currentPage: pagination.current, pageSize: pagination.pageSize, search });
    }, [pagination, search]);

    useEffect(() => {
        if (
            (isLoading || isFetching) &&
            (searchAndPaginatioCache.search !== search || searchAndPaginatioCache.pagination !== pagination)
        ) {
            setLoading(true);
            setSearchAndPaginationCache({ search, pagination });
        } else {
            setLoading(false);
        }
    }, [isLoading, isFetching, pagination, search]);

    return (
        <StyledPageLayout>
            <StyledPageHeader>
                <h1>CabSupport</h1>
                <SupportForm onChange={handleSearchChange} />
            </StyledPageHeader>
            <StyledPageContent>
                <SupportRequestsTable
                    isLoading={loading}
                    handleTableChange={handlePaginationChange}
                    data={supportRequestsData?.supportRequests || []}
                    pagination={{ ...pagination, total: supportRequestsData?.total || 0 }}
                />
            </StyledPageContent>
        </StyledPageLayout>
    );
}
