import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-duration-format';
import { ClockCircleOutlined } from '@ant-design/icons';

import { TwilioModal } from '../../models/call.models';
import { Wrapper, IconWrapper, TimeWrapper } from './TwilioTimer.styles';

export const TwilioTimer: FC<Pick<TwilioModal, 'timerStartedAt'>> = ({ timerStartedAt }) => {
    const [dynamicKey, setDynamicKey] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => setDynamicKey(Math.random()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return timerStartedAt ? (
        <Wrapper key={dynamicKey}>
            <IconWrapper>
                <ClockCircleOutlined />
            </IconWrapper>
            <TimeWrapper>
                {moment
                    .duration(moment(moment()).diff(moment(timerStartedAt), 'milliseconds'), 'millisecond')
                    .format('mm:ss', { trim: false })}
            </TimeWrapper>
        </Wrapper>
    ) : null;
};
