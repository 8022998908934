import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { store, persistor } from './store/store';
import App from './App';
import { Login } from './views/login/Login';
// YY-413 REQUESTED TO HIDE THIS
// import { Dashboard } from './views/dashboard/Dashboard';
import { Drivers } from './views/drivers/Drivers';
import PrivateElement from './components/access/PrivateElement';

import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.min.css';
import './index.css';
import { LoginForm } from './views/login/components/LoginForm';
import { SignupForm } from './views/login/components/SignupForm';
import { ForgotPasswordForm } from './views/login/components/ForgotPasswordForm';
import { Error404 } from './views/error-404/Error404';
import { Support } from './views/support/Support';
import { UserTracker } from './components/user-tracking/UserTracker';
import { AppContextProvider } from './contexts/AppContextProvider';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <AppContextProvider>
            <UserTracker>
                <PersistGate loading="loading" persistor={persistor}>
                    <Router>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <PrivateElement>
                                        <App />
                                    </PrivateElement>
                                }
                            >
                                {/*
                        YY-413 REQUESTED TO HIDE THIS
                        <Route path="" element={<Dashboard />} />
                        */}
                                <Route path="" element={<Navigate to="/drivers" replace />} />
                                <Route path="drivers" element={<Drivers />} />
                                <Route path="support" element={<Support />} />
                                <Route path="settings" element={<div>Settings</div>} />
                                <Route path="*" element={<Error404 />} />
                            </Route>
                            <Route path="/login" element={<Login />}>
                                <Route path="" element={<LoginForm />} />
                                <Route path="signup" element={<SignupForm />} />
                                <Route path="reset" element={<ForgotPasswordForm />} />
                            </Route>
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                    </Router>
                </PersistGate>
            </UserTracker>
        </AppContextProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
