import React, { FC, useState, useCallback, useContext } from 'react';

import {
    useGetVideoTokenQuery,
    VideoCallEventType,
    useRegiserVideoCallEventMutation,
} from '../../store/communication/communication-api.slice';
import { TwilioRoom } from './components/TwilioRoom/TwilioRoom';
import { updateVideoCall } from '../../store/communication/communication.slice';
import { useAppDispatch } from '../../store/hooks';
import { notification } from 'antd';
import { AppContext } from '../../contexts/AppContextProvider';

export interface CallProps {
    roomName: string;
    isFullscreen: boolean;
    toggleFullscreen: () => void;
    defaultCamera?: boolean;
    defaultMic?: boolean;
}

export const Call: FC<CallProps> = ({
    roomName,
    toggleFullscreen,
    isFullscreen,
    defaultCamera = true,
    defaultMic = true,
}) => {
    const dispatch = useAppDispatch();
    const { data, isLoading } = useGetVideoTokenQuery(roomName || '');
    const [registerVideoCallEvent] = useRegiserVideoCallEventMutation();

    const [remainInCall, setRemainInCall] = useState(true);

    const [notificationApi, contextHolder] = notification.useNotification();

    const { appName } = useContext(AppContext);

    const handleLogout = useCallback(() => {
        setRemainInCall(false);
        dispatch(updateVideoCall(null));
        registerVideoCallEvent({ roomName, eventType: VideoCallEventType.userDisconnected, appName });
    }, [appName]);

    return (
        <>
            {contextHolder}
            {!isLoading && data && (
                <TwilioRoom
                    videoToken={data.token}
                    showNotification={(message: string) => {
                        notificationApi.info({ message });
                    }}
                    roomName={remainInCall ? roomName : ''}
                    handleLogout={handleLogout}
                    toggleFullscreen={toggleFullscreen}
                    isFullscreen={isFullscreen}
                    onVideoClientInit={() => {
                        registerVideoCallEvent({ roomName, eventType: VideoCallEventType.userJoined, appName });
                    }}
                    onVideoClientError={(error: Error) =>
                        registerVideoCallEvent({ roomName, eventType: VideoCallEventType.error, appName })
                    }
                    timerStartedAt={0}
                    defaultCamera={defaultCamera}
                    defaultMic={defaultMic}
                />
            )}
        </>
    );
};
