import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../lib/axios-base-query';
import { IUser, updateMe } from './users.slice';

export const userApiSlice = createApi({
    reducerPath: 'userApi',
    baseQuery: axiosBaseQuery('user/'),
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => ({
                url: 'me',
                method: 'get',
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
                dispatch(updateMe(data));
            },
        }),
        getUser: builder.query<IUser, string>({
            query: (userId) => ({
                url: userId,
                method: 'get',
            }),
        }),
    }),
});

export const { useGetMeQuery, useGetUserQuery } = userApiSlice;
