import React, { useCallback, useContext, useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import { useStartVideoCallMutation } from '../../../store/communication/communication-api.slice';
import { AppContext } from '../../../contexts/AppContextProvider';

interface CallButtonProps {
    id: string;
    disabled?: boolean;
    onError?: () => void;
}

export const CallButton: React.FC<CallButtonProps> = ({ id, disabled, onError }) => {
    const [startVideoCall, { isLoading, isError }] = useStartVideoCallMutation();
    const { appName } = useContext(AppContext);

    const handleClick = useCallback(() => {
        startVideoCall({ recipientId: id, appName });
    }, [id]);

    useEffect(() => {
        if (isError) {
            onError?.();
        }
    }, [isError]);

    return (
        <Tooltip title={disabled ? 'Video calling is disabled due to driver status' : ''} placement="topLeft">
            <Button
                disabled={disabled}
                icon={<VideoCameraOutlined />}
                type="primary"
                onClick={handleClick}
                loading={isLoading}
            />
        </Tooltip>
    );
};
