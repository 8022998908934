import React, { FC, useEffect, useState } from 'react';

import {
    ActionsWrapper,
    EndCallButton,
    ToggleButton,
    // ToggleChatButton,
    ToggleSizeButton,
} from './TwilioActions.styles';
import { ReactComponent as FullScreenOff } from '../../../../assets/shrink.svg';
import { ReactComponent as FullScreenOn } from '../../../../assets/expand.svg';
import { ReactComponent as MicOnIcon } from '../../../../assets/mic-on.svg';
import { ReactComponent as MicOffIcon } from '../../../../assets/mic-off.svg';
import { ReactComponent as ConversationPhoneIcon } from '../../../../assets/conversation-phone.svg';
import { ReactComponent as CamOnIcon } from '../../../../assets/cam-on.svg';
import { ReactComponent as CamOffIcon } from '../../../../assets/cam-off.svg';
// import { ReactComponent as ChatOffIcon } from '../../../../assets/chat-off.svg';
// import { ReactComponent as ChatOnIcon } from '../../../../assets/chat-on.svg';

interface TwilioActionsProps {
    isFullscreen: boolean | undefined;
    toggleFullscreen: () => void;
    toggleAudio: () => void;
    isAudio: boolean;
    toggleCamera: () => void;
    isCamera: boolean;
    onLogout: () => void;
    // toggleChat: () => void;
    // isMessageChatOpen: boolean | undefined;
    // hasUnreadMessages: boolean | undefined;
}

export const TwilioActions: FC<TwilioActionsProps> = ({
    isFullscreen,
    toggleFullscreen,
    toggleAudio,
    isAudio,
    toggleCamera,
    isCamera,
    onLogout,
    // toggleChat,
    // isMessageChatOpen,
    // hasUnreadMessages,
}) => {
    const [isVideoInitialized, setIsVideoInitialized] = useState(false);
    const [isCameraButtonDisabled, setIsCameraButtonDisabled] = useState(false);
    const [isAudioButtonDisabled, setIsAudioButtonDisabled] = useState(false);

    // TODO: Added here to add more time for video initialization. After GTM release real video initialization event should be captured
    useEffect(() => {
        setTimeout(() => setIsVideoInitialized(true), 1000);
    }, []);

    const handleToggleAudio = () => {
        setIsAudioButtonDisabled(true);
        toggleAudio();
        setTimeout(() => setIsAudioButtonDisabled(false), 500);
    };

    const handleToggleCamera = () => {
        setIsCameraButtonDisabled(true);
        toggleCamera();
        setTimeout(() => setIsCameraButtonDisabled(false), 500);
    };

    return (
        <ActionsWrapper>
            <div>
                <ToggleSizeButton
                    $isOff={!isFullscreen}
                    onClick={toggleFullscreen}
                    icon={isFullscreen ? <FullScreenOff /> : <FullScreenOn />}
                />
                <ToggleButton
                    onClick={handleToggleAudio}
                    icon={isAudio ? <MicOnIcon /> : <MicOffIcon />}
                    $isOff={!isAudio}
                    disabled={!isVideoInitialized || isAudioButtonDisabled}
                />
            </div>
            <div>
                <EndCallButton onClick={onLogout} icon={<ConversationPhoneIcon />} disabled={!isVideoInitialized} />
            </div>
            <div>
                <ToggleButton
                    onClick={handleToggleCamera}
                    icon={isCamera ? <CamOnIcon /> : <CamOffIcon />}
                    $isOff={!isCamera}
                    disabled={!isVideoInitialized || isCameraButtonDisabled}
                />
                {/* <ToggleChatButton
                    $isOff={!isMessageChatOpen}
                    onClick={toggleChat}
                    icon={isMessageChatOpen ? <ChatOffIcon /> : <ChatOnIcon />}
                    $hasUnreadMessages={Boolean(hasUnreadMessages)}
                /> */}
            </div>
        </ActionsWrapper>
    );
};
