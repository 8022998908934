import { useContext, useEffect } from 'react';
import { AppContext } from '../contexts/AppContextProvider';
import { AppNames } from '../types/AppNames';

export const useAppName = (appName: AppNames) => {
    const { setAppName } = useContext(AppContext);

    useEffect(() => {
        setAppName(appName);
    }, []);

    return;
};
