import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    background-color: #202124;
    border-radius: 10px;
`;

export const SpinWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: #fff;
`;
