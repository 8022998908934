import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as AppIconSVG } from '../../../assets/app-icon.svg';

const StyledSidebarHeaderMenu = styled(Menu)`
    border-right: 0px;
`;

const StyledSidebarHeaderMenuItem = styled(Menu.Item)`
    height: 32px !important;
    line-height: 32px !important;
    font-weight: 600;
    font-size: 18px;
    .anticon {
        height: 32px !important;
        min-width: 32px !important;
        font-size: 32px !important;
    }
    .ant-menu-inline-collapsed & {
        padding-left: 23.5px !important;
    }
    .ant-menu-title-content {
        height: 32px !important;
        display: flex;
        align-items: center;
    }
`;

export const SidebarHeader = () => {
    return (
        <StyledSidebarHeaderMenu selectedKeys={[]}>
            <StyledSidebarHeaderMenuItem key="1">
                <Icon component={AppIconSVG} />
                <span>
                    <Link to="/drivers">Transpoworks</Link>
                </span>
            </StyledSidebarHeaderMenuItem>
        </StyledSidebarHeaderMenu>
    );
};
