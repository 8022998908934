import React from 'react';
import { UserOutlined } from '@ant-design/icons';

interface RequestedByUserProps {
    user: {
        firstName: string;
        lastName: string;
    };
}

export const RequestedByUser: React.FC<RequestedByUserProps> = ({ user }) => {
    return user?.firstName || user?.lastName ? (
        <>
            <UserOutlined /> {`${user.firstName} ${user.lastName}`}
        </>
    ) : (
        <>
            <UserOutlined /> {'Unknown User'}
        </>
    );
};
