import React, { useState } from 'react';
import { AppNames } from '../types/AppNames';

export const AppContext = React.createContext<{ appName: AppNames; setAppName: (appName: AppNames) => void }>({
    appName: AppNames.CabChat,
    setAppName: (appName: AppNames) => {},
});

export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [appName, setAppName] = useState<AppNames>(AppNames.CabChat);
    return <AppContext.Provider value={{ appName, setAppName }}>{children}</AppContext.Provider>;
};
