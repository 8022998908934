/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken, deleteToken } from 'firebase/messaging';
import { message, notification } from 'antd';
import { useRegisterForPushNotificationsMutation } from '../../store/communication/communication-api.slice';

import { JoinCallModal } from './components/JoinCallModal';

interface InitiateCallMessage {
    roomName: string;
    userId: string;
}
const notificationSound = new Audio('./notification_sound.wav');
notificationSound.load();
notificationSound.loop = true;

export const NotificationsWidget: React.FC = () => {
    const [registerForPushNotification, registerForPushNotificationResult] = useRegisterForPushNotificationsMutation();

    const [data, setData] = useState<InitiateCallMessage | null>(null);

    const [notificationApi, contextHolder] = notification.useNotification();

    const hadleInitiateCall = useCallback((data: InitiateCallMessage) => {
        setData(data);
    }, []);

    const closeCallModal = useCallback(() => {
        notificationSound.pause();
        setData(null);
    }, []);

    const connectToNotificationServer = useCallback(() => {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
        };
        const firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, (data) => {
            const message = JSON.parse(data.data?.twi_body || 'null');

            if (data.data?.notificationType === 'INITIATE_CALL') {
                try {
                    notificationSound.play();
                } catch (e) {
                    console.error(e);
                }
                hadleInitiateCall({
                    roomName: message?.body,
                    userId: message?.senderId,
                });
            }
        });

        getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
                return registerForPushNotification(currentToken);
            }
        });

        const handleMessageEvent = (event: { data: { type: string; body: any; senderId: any } }) => {
            if (event.data?.type === 'INITIATE_CALL') {
                try {
                    notificationSound.play();
                } catch (e) {
                    console.error(e);
                }
                hadleInitiateCall({
                    roomName: event.data?.body,
                    userId: event.data?.senderId,
                });
            }
        };

        navigator.serviceWorker.addEventListener('message', handleMessageEvent);

        return () => {
            deleteToken(messaging);
            navigator.serviceWorker.removeEventListener('message', handleMessageEvent);
        };
    }, []);

    useEffect(() => {
        if (Notification.permission === 'granted') {
            connectToNotificationServer();
        }
    }, []);

    useEffect(() => {
        if (!registerForPushNotificationResult.isLoading && registerForPushNotificationResult.error) {
            message.error('Connection to notification server failed. Retrying...');
            setTimeout(() => {
                connectToNotificationServer();
            }, 10000);
        }
    }, [registerForPushNotificationResult]);

    return (
        <>
            {contextHolder}
            {data && (
                <JoinCallModal
                    showNotification={(message) => {
                        notificationApi.info({ message });
                    }}
                    open={!!data}
                    roomName={data?.roomName || ''}
                    callerId={data?.userId || ''}
                    close={closeCallModal}
                />
            )}
        </>
    );
};
