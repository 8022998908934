import { Layout, Menu } from 'antd';
import styled from 'styled-components';

export const StyledAppLayoutSider = styled(Layout.Sider)`
    height: 100vh;
    padding: 24px 0px 48px 0px;
    border-right: 1px solid #eaeaea;
    .ant-layout-sider-trigger {
        border-right: 1px solid #eaeaea;
    }
`;

export const StyledAppLayoutMenu = styled(Menu)`
    height: calc(100% - 48px);
    border-right: 0px;
    display: flex;
    flex-direction: column;
    & > li:first-child {
        flex-grow: 1;
    }
`;

export const StyledAppLayoutContent = styled(Layout.Content)`
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
`;
