import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig } from 'axios';
import { updateAccessToken } from '../store/auth/auth.slice';
import { RootState } from '../store/store';

export type AxiosBaseQueryParams = {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
};

export type AxiosBaseQueryError = { status: number; data: any | string };

export type AxiosBaseQueryFn = BaseQueryFn<AxiosBaseQueryParams, unknown, AxiosBaseQueryError>;

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const axiosBaseQuery =
    (baseUrl: string): AxiosBaseQueryFn =>
    async ({ url, method, data, params, headers }, { getState, dispatch }) => {
        try {
            const token = (getState() as RootState).authSlice.accessToken;
            const result = await axiosInstance({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`,
                },
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as any;

            const error = {
                status: err?.status || err?.response?.status,
                data: !err?.response ? err : err.response?.data || err.message,
            };

            if (error.status === 401) {
                dispatch(updateAccessToken(null));
            }

            return { error };
        }
    };
