import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    position: relative;
`;

export const VideoWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    position: absolute;
`;

export const VideoShadow = styled.div<{ $isMessageChatOpen?: boolean }>`
    height: 195px;
    width: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0));
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: ${({ $isMessageChatOpen }) => ($isMessageChatOpen ? '0' : '8px')};
`;

export const ParticipantWrapper = styled.div`
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 8px;
    width: 45%;
    max-width: 250px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    svg {
        width: 40px !important;
        height: 40px !important;
        margin: 25% 0;
    }
`;
