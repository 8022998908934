import { SearchOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';

export interface ISupportFormData {
    search: string;
}

interface DriversFormProps {
    onChange: (values: ISupportFormData) => void;
}

export const SupportForm: React.FC<DriversFormProps> = ({ onChange }) => {
    return (
        <Form layout="inline" initialValues={{ search: '' }} onValuesChange={debounce(onChange, 500)}>
            <Form.Item name="search" noStyle>
                <Input placeholder="Search" prefix={<SearchOutlined />} allowClear />
            </Form.Item>
        </Form>
    );
};
