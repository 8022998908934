import { createListenerMiddleware, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState, persistor } from '../store';

interface AuthState {
    accessToken?: string | null;
}

const initialState: AuthState = {
    accessToken: null,
};

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        updateAccessToken(state, action: PayloadAction<string | null>) {
            if (action.payload === null && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage('logout');
            }
            state.accessToken = action.payload;
        },
    },
});

export const { updateAccessToken } = authSlice.actions;

export const authMiddleware = createListenerMiddleware();

const authTokenPredicate = (action: any, currentState: any, previousState: any) => {
    return currentState.authSlice.accessToken !== previousState.authSlice.accessToken;
};

const updateAction = updateAccessToken(null);

authMiddleware.startListening({
    predicate: authTokenPredicate,
    effect: async (action, listenerApi) => {
        if (action.type !== updateAction.type) {
            return;
        }

        await listenerApi.condition(() => true, 1000);
        if (action.payload) {
            persistor.persist();
        } else {
            persistor.purge();
        }
    },
});

export const selectAccessToken = (state: RootState) => state.authSlice.accessToken;

export const authReducer = persistReducer(
    {
        key: 'rtk:auth',
        storage,
        whitelist: ['accessToken'],
    },
    authSlice.reducer
);
