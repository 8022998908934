import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { communicationApiSlice } from './communication/communication-api.slice';
import { authApiSlice } from './auth/auth-api.slice';
import { usersApiSlice } from './users/users-api.slice';
import { authSlice, authReducer, authMiddleware } from './auth/auth.slice';
import { usersSlice } from './users/users.slice';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { userApiSlice } from './users/user-api.slice';
import { supportApiSlice } from './support/support-api.slice';
import { communicationSlice } from './communication/communication.slice';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
    [communicationApiSlice.reducerPath]: communicationApiSlice.reducer,
    [communicationSlice.name]: communicationSlice.reducer,
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [authSlice.name]: authReducer,
    [usersSlice.name]: usersSlice.reducer,
    [usersApiSlice.reducerPath]: usersApiSlice.reducer,
    [userApiSlice.reducerPath]: userApiSlice.reducer,
    [supportApiSlice.reducerPath]: supportApiSlice.reducer,
});

const logoutAction = authSlice.actions.updateAccessToken(null);

const rootReducer = (state: any, action: any) => {
    if (action.type === logoutAction.type && action.payload === logoutAction.payload) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .prepend(authMiddleware.middleware)
            .concat([
                authApiSlice.middleware,
                communicationApiSlice.middleware,
                usersApiSlice.middleware,
                userApiSlice.middleware,
                supportApiSlice.middleware,
            ]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
