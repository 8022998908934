import { AsyncState } from '../../../types/AsyncState';

export enum ParticipantVideoType {
    LOCAL = 'LOCAL',
    REMOTE = 'REMOTE',
    LOCAL_PREVIEW = 'LOCAL_PREVIEW',
}

export enum TwilioErrorMessages {
    PERMISSION_DENIED = 'Permission denied',
    DEVICE_NOT_FOUND = 'Requested device not found',
    PERMISSION_DENIED_FOR_PLATFORM_BY_USER = 'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
}

export enum TwilioErrorNames {
    NOT_ALLOWED = 'NotAllowedError',
}

export interface TwilioClientError {
    message: string;
    name: string;
}

export interface TwilioModal {
    open: boolean;
    videoToken: AsyncState<{ token: string | null }>;
    conversationToken: AsyncState<{ token: string | null }>;
    roomName?: string;
    conversationSid?: string;
    messageChat?: boolean;
    fullScreen?: boolean;
    unreadMessages?: boolean;
    videoClientError?: TwilioClientError;
    isVideoClientInitialized?: boolean;
    timerStartedAt: number | undefined;
}
