import Icon, { UserOutlined } from '@ant-design/icons';
import { notification, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React from 'react';

import { StatusTagStatus, StyledTableWrapper } from '../../../components/UI';
import { StatusTag } from '../../../components/UI/Status/StatusTag';
import { IDataUsage, IUser } from '../../../store/users/users.slice';
import { CallButton } from './CallButton';
import { DataRemainingLabel } from './labels/DataRemainingLabel';
import { ReactComponent as MarkerIcon } from './../../../assets/marker.svg';
import { NotificationInstance } from 'antd/es/notification';

interface DriverTableProps {
    isLoading: boolean;
    data: IUser[];
    dataLimitGB?: number;
    pagination: TablePaginationConfig;
    handleTableChange: (pagination: TablePaginationConfig) => void;
}

interface IDriverRow extends IUser {
    onlineStatus?: boolean;
    lastHosEvent?: {
        type: string;
        code: string;
    };
    dataLimitGB?: number;
}

export enum HosEventCodes {
    OffDuty = '1',
    SleeperBerth = '2',
    Driving = '3',
    OnDuty = '4',
}

export const mapDriverStatus = (onlineStatus: boolean, isDriving: boolean, pendingCall?: boolean) => {
    let status;
    if (pendingCall) {
        return StatusTagStatus.IN_PROGRESS;
    } else {
        if (onlineStatus) {
            if (isDriving) {
                status = StatusTagStatus.UNAVAILABLE;
            } else {
                status = StatusTagStatus.AVAILABLE;
            }
        } else {
            status = StatusTagStatus.OFFLINE;
        }
    }
    return status;
};

const getDriverColumns = ({ notificationApi }: { notificationApi: NotificationInstance }): ColumnsType<IDriverRow> => {
    return [
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'name',
            render: (name, user) => (
                <>
                    <UserOutlined />
                    {user.firstName || user.lastName ? ` ${user.firstName} ${user.lastName}` : ' Unknown User'}
                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',

            render: (status, user) => {
                const driverStatus = mapDriverStatus(
                    !!user.onlineStatus,
                    user.lastHosEvent?.code === HosEventCodes.Driving
                );
                return <StatusTag status={driverStatus} />;
            },
        },
        {
            title: 'Vehicle ID',
            dataIndex: 'vehicle',
            key: 'vehicle',
            render: (vehicle, row) => {
                return row.onlineStatus ? vehicle?.externalId || 'N/A' : 'N/A';
            },
        },
        {
            title: 'Data Remaining',
            dataIndex: 'dataUsage',
            key: 'dataRemaining',
            render: (du: IDataUsage, row) => <DataRemainingLabel dataUsage={du} dataLimitGB={row.dataLimitGB} />,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (location: { longitude: number; latitude: number }) => {
                if (location) {
                    return (
                        <>
                            <a
                                target={'_blank'}
                                href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
                                rel="noreferrer"
                            >
                                <Icon style={{ fontSize: '16px', paddingRight: '2px' }} component={MarkerIcon} /> View
                                Map
                            </a>
                        </>
                    );
                } else {
                    return 'N/A';
                }
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'callButton',
            width: 1,
            align: 'right',
            render: (id, row) => {
                const disabled = !row.onlineStatus || row.lastHosEvent?.code === HosEventCodes.Driving;

                const onError = () =>
                    notificationApi.info({
                        message: `${row.firstName} ${row.lastName} is currently unavailable`,
                    });

                return (
                    <>
                        <CallButton id={id} disabled={disabled} onError={onError} />
                    </>
                );
            },
        },
    ];
};

function addGlobalProperties(table: any[], properites: object): any[] {
    return table.map((item) => ({ ...item, ...properites }));
}

export const DriversTable: React.FC<DriverTableProps> = ({
    data,
    isLoading,
    dataLimitGB,
    pagination,
    handleTableChange,
}) => {
    const handlOnChange = (pagination: TablePaginationConfig) => {
        handleTableChange({
            pageSize: pagination.pageSize,
            current: (pagination.current || 1) - 1,
        });
    };

    const [notificationApi, contextHolder] = notification.useNotification();

    const cols = getDriverColumns({ notificationApi });

    return (
        <StyledTableWrapper>
            {contextHolder}
            <Table
                rowKey="id"
                dataSource={addGlobalProperties(data, { dataLimitGB })}
                loading={isLoading}
                columns={cols}
                onChange={handlOnChange}
                pagination={{
                    total: pagination.total || 0,
                    pageSize: pagination.pageSize || 10,
                    current: (pagination.current || 0) + 1,
                }}
            />
        </StyledTableWrapper>
    );
};
