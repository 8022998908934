import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { LocalParticipant, LocalVideoTrack, RemoteParticipant, RemoteVideoTrack } from 'twilio-video';
import { ImageFit, VirtualBackgroundProcessor } from '@twilio/video-processors';

import { StyledVideo, VideoPlaceholder, Wrapper } from './TwilioParticipant.styles';
import { ReactComponent as CameraPlaceholder } from '../../../../assets/participant-placeholder.svg';
import ProctoringVirtualBackground from '../../../../assets/proctoring-video-virtual-background-white.png';
import { ParticipantVideoType } from '../../models/call.models';

interface TwilioParticipantProps {
    participant: RemoteParticipant | LocalParticipant;
    isMessageChatOpen?: boolean | undefined;
    participantJoined?: boolean;
    participantVideoType?: ParticipantVideoType;
}

const virtualBackgroundAssets = '/virtualbackground';
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

export const loadVirtualBg = async (videoTrack: LocalVideoTrack | RemoteVideoTrack, ref?: any) => {
    if (videoTrack && !videoTrack.processor) {
        const img = new Image();

        img.src = ProctoringVirtualBackground;

        img.onload = async () => {
            virtualBackgroundProcessor = new VirtualBackgroundProcessor({
                assetsPath: virtualBackgroundAssets,
                backgroundImage: img,
                maskBlurRadius: 4,
                fitType: ImageFit.Fill,
            });

            if (videoTrack.isStarted) {
                try {
                    await virtualBackgroundProcessor.loadModel();
                    videoTrack.addProcessor(virtualBackgroundProcessor);
                } catch (e) {}
            }

            videoTrack.attach(ref);
        };
    } else {
        videoTrack.attach(ref);
    }
};

export const TwilioParticipant: FC<TwilioParticipantProps> = ({
    participant,
    isMessageChatOpen,
    participantJoined,
    participantVideoType,
}) => {
    // TODO: Handle types in the final solution
    const [videoTracks, setVideoTracks] = useState<any[]>([]);
    const [audioTracks, setAudioTracks] = useState<any[]>([]);
    const [isVideo, setIsVideo] = useState<boolean>(true);

    const videoRef: RefObject<HTMLVideoElement> = useRef(null);
    const audioRef: RefObject<HTMLAudioElement> = useRef(null);

    const trackpubsToTracks = (trackMap: Map<string, any>) =>
        Array.from(trackMap.values())
            .map((publication: any) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        const trackSubscribed = (track: any) => {
            if (track.kind === 'video') {
                setIsVideo(true);
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === 'audio') {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = (track: any) => {
            if (track.kind === 'video') {
                setIsVideo(false);
                setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
            } else if (track.kind === 'audio') {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            }
        };

        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);
        participant.on('trackPublished', (track) => {
            if (track?.track?.kind === 'video') {
                trackSubscribed(track.track);
            }
        });
        participant.on('trackStopped', (track) => {
            if (track?.kind === 'video') {
                trackUnsubscribed(track);
            }
        });

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners('trackSubscribed');
            participant.removeAllListeners('trackUnsubscribed');
            participant.removeAllListeners('trackStopped');
            participant.removeAllListeners('trackPublished');
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[0];

        if (videoTrack) {
            videoTrack.attach(videoRef.current);

            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks, participantVideoType]);

    useEffect(() => {
        const audioTrack = audioTracks[0];

        if (audioTrack) {
            audioTrack.attach(audioRef.current);

            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    return (
        <Wrapper>
            {isVideo && videoTracks.length > 0 ? (
                <StyledVideo
                    ref={videoRef}
                    controls={false}
                    muted={true}
                    $isMessageChatOpen={isMessageChatOpen}
                    $participantJoined={participantJoined}
                    $participantVideoType={participantVideoType}
                />
            ) : (
                <VideoPlaceholder>
                    <CameraPlaceholder />
                </VideoPlaceholder>
            )}
            <audio ref={audioRef} autoPlay muted={false} />
        </Wrapper>
    );
};
