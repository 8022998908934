import { useNavigate } from 'react-router-dom';
import { Button, Space } from 'antd';
import styled from 'styled-components';

export const Error404: React.FC = () => {
    const navigate = useNavigate();
    const onGoBackClick = () => {
        navigate(-1);
    };

    return (
        <Container size="middle">
            <Title>404</Title>
            <h3>Oops. The page you were looking for doesn't exist.</h3>
            <p>You may have mistyped the address or the page may have moved.</p>
            <Button onClick={onGoBackClick} type="primary" size="large">
                Go Back
            </Button>
        </Container>
    );
};

const Title = styled.h1`
    color: #000;
    font-weight: 800;
    font-size: 4rem;
    line-height: 77px;
`;

const Container = styled(Space)`
    flex: 1;
    flex-direction: column;
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: 100vh;
`;
