import Icon from '@ant-design/icons';
import { Row } from 'antd';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as AppIconSVG } from '../../assets/app-icon.svg';
import { FormColumn } from './components/UI';

const StyledTitleAndIconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        font-size: 42px;
        font-weight: 600;
        margin-top: 16px;
    }
`;

export function Login() {
    return (
        <Row>
            <FormColumn span={12}>
                <StyledTitleAndIconWrapper>
                    <Icon component={AppIconSVG} style={{ fontSize: 180 }} />
                    <p>Transpoworks</p>
                </StyledTitleAndIconWrapper>
            </FormColumn>
            <FormColumn span={12}>
                <Outlet />
            </FormColumn>
        </Row>
    );
}
