import { Tag, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IDataUsage } from '../../../../store/users/users.slice';

const StyledTag = styled(Tag)`
    border: none;
    background-color: transparent;

    & > b {
        background-color: transparent;
    }
`;

const toPercent = (x: number) => Math.round(x * 100);

const matchColor = (x: number) => {
    if (x < 10) return 'error';
    if (x < 50) return 'warning';
    return 'success';
};

interface DataRemainingLabelProps {
    dataUsage?: IDataUsage;
    dataLimitGB?: number;
}

export const DataRemainingLabel: React.FC<DataRemainingLabelProps> = ({ dataUsage, dataLimitGB = 0 }) => {
    const maxDataMB = dataLimitGB * 1024;
    let dataUsed = 0;

    if (dataUsage) {
        dataUsed = dataUsage.downloadMB + dataUsage.uploadMB;
    }

    const dataRemaining = maxDataMB ? 1 - dataUsed / maxDataMB : 1;
    const percentage = toPercent(dataRemaining);

    return (
        <Tooltip title={`${Math.round(maxDataMB - dataUsed)}MB / ${Math.round(maxDataMB)}MB`}>
            <StyledTag color={matchColor(percentage)}>{`${Math.max(percentage, 0)}%`}</StyledTag>
        </Tooltip>
    );
};
