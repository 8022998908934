import styled from 'styled-components';
import { Tag, TagProps } from 'antd';

const StyledTag = styled(Tag)`
    border-radius: 2em;
    padding: 4px 7px;

    & > span {
        margin-left: 0.5em;
    }

    & > b {
        background-color: transparent;
    }
`;

const statusMap = {
    active: {
        className: 'ant-tag-success',
        label: 'Active',
    },
    inactive: {
        className: 'ant-tag-error',
        label: 'Inactive',
    },
    unknown: {
        className: 'ant-tag-warning',
        label: 'Unknown',
    },

    unavailable: {
        className: 'ant-tag-warning',
        label: 'Unavailable',
    },
    available: {
        className: 'ant-tag-success',
        label: 'Available',
    },
    in_progress: {
        className: 'ant-tag-processing',
        label: 'In Progress',
    },
    offline: {
        className: 'ant-tag-error',
        label: 'Offline',
    },
};

export enum StatusTagStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    UNKNOWN = 'unknown',

    UNAVAILABLE = 'unavailable',
    AVAILABLE = 'available',
    IN_PROGRESS = 'in_progress',
    OFFLINE = 'offline',
}

interface StatusTagProps extends TagProps {
    status: StatusTagStatus;
}

export const StatusTag: React.FC<StatusTagProps> = ({ status = StatusTagStatus.UNKNOWN, ...props }) => {
    const circle = '\u25cf';
    const statusConf = statusMap[status] || statusMap.unknown;
    return (
        <StyledTag {...props} icon={<b className={statusConf.className}>{circle}</b>}>
            {statusConf.label}
        </StyledTag>
    );
};
