import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { message, Space } from 'antd';
import { Form, Input, SubmitButton } from 'formik-antd';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { useRegisterMutation } from '../../../store/auth/auth-api.slice';
import { AxiosBaseQueryError } from '../../../lib/axios-base-query';
import { FormCard } from './UI';

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
    password2: Yup.string()
        .required('Confirm your password')
        .oneOf([Yup.ref('password')], "Passwords don't match"),
});

export const SignupForm: React.FC = () => {
    const [triggerRegister, registerResult] = useRegisterMutation();

    useEffect(() => {
        if (registerResult.error) {
            const registerError = registerResult.error as AxiosBaseQueryError;
            message.error(registerError.data, 2);
        }
    }, [registerResult.error]);

    useEffect(() => {
        if (!registerResult.isLoading && registerResult.isSuccess) {
            message.success('Registered');
        }
    }, [registerResult.isSuccess, registerResult.isLoading]);

    return (
        <FormCard title="Sign Up">
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={async (data, { resetForm }) => {
                    triggerRegister(data);
                    resetForm();
                }}
                validationSchema={SignupSchema}
            >
                {() => (
                    <Form>
                        <Space size="large" direction="vertical" style={{ width: '100%' }}>
                            <Form.Item name="email">
                                Email
                                <Input name="email" placeholder="email" />
                            </Form.Item>
                            <Form.Item name="password">
                                Password
                                <Input.Password name="password" />
                            </Form.Item>
                            <Form.Item name="password2">
                                Repeat Password
                                <Input.Password name="password2" />
                            </Form.Item>
                            <SubmitButton loading={registerResult.isLoading} block>
                                Sign Up
                            </SubmitButton>
                            <Space size="small" direction="vertical">
                                <p>
                                    Already have an Account? <Link to="..">Log In</Link>
                                </p>
                                <p>
                                    By signing up, I agree to <Link to="">Terms of Service</Link>
                                    {' & '} <Link to="">Privacy Policy.</Link>
                                </p>
                            </Space>
                        </Space>
                    </Form>
                )}
            </Formik>
        </FormCard>
    );
};
