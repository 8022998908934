import { message, Space } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AxiosBaseQueryError } from '../../../lib/axios-base-query';
import { useLoginMutation } from '../../../store/auth/auth-api.slice';
import { FormCard } from './UI';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

export const LoginForm: React.FC = () => {
    const [triggerLogin, loginResult] = useLoginMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (loginResult.error) {
            const loginError = loginResult.error as AxiosBaseQueryError;
            message.error(loginError.data?.message || loginError.data, 2);
        }
    }, [loginResult.error]);

    useEffect(() => {
        if (!loginResult.isLoading && loginResult.isSuccess) {
            navigate('/');
        }
    }, [loginResult.isSuccess, loginResult.isLoading]);

    return (
        <FormCard title="Log In">
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={async (data, { resetForm }) => {
                    triggerLogin(data);
                    resetForm();
                }}
                validationSchema={LoginSchema}
            >
                {() => (
                    <Form>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Form.Item name="email">
                                Email
                                {/* Adding suffix={<span /> prevents loosing focus when typing}*/}
                                <Input suffix={<span />} name="email" placeholder="email" />
                            </Form.Item>
                            <Form.Item name="password">
                                Password
                                <Input.Password name="password" />
                            </Form.Item>
                            <SubmitButton loading={loginResult.isLoading} block>
                                Log In
                            </SubmitButton>
                            <Space size="small" direction="vertical">
                                <p>
                                    Contact <a href="mailto: support@transpoworks.com">support@transpoworks.com</a> to
                                    request an account.
                                </p>
                            </Space>
                        </Space>
                    </Form>
                )}
            </Formik>
        </FormCard>
    );
};
