import styled from 'styled-components';
import { Modal } from 'antd';

export const CallModal = styled(Modal)`
    & .ant-modal-title {
        font-weight: 700;
    }

    & .ant-modal-body {
        text-align: center;
        padding-bottom: 0;
    }
    & .ant-modal-footer {
        display: flex;
        & > * {
            flex-grow: 1;
        }
    }
`;
