import React from 'react';
import { format } from 'date-fns';

interface RequestTimeProps {
    timestamp: number;
}

export const RequestTime: React.FC<RequestTimeProps> = ({ timestamp }) => {
    if (isNaN(timestamp)) {
        return <></>;
    }

    const date = new Date(timestamp * 1000);
    const now = new Date();

    if (date.toLocaleDateString() !== now.toLocaleDateString()) {
        return <>{date.toLocaleDateString()}</>;
    }
    return <>{format(date, 'H:mm a')}</>;
};
