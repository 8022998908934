import { Space, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React from 'react';

import { StatusTag, StyledTableWrapper } from '../../../components/UI';
import { ISupportRequest, SupportRequestStatus } from '../../../store/support/support-api.slice';
import { CallButton } from './CallButton';
import { RequestedByUser } from './RequestedByUser';
import { RequestTime } from './RequestTime';
import { SupportAgent } from './SupportAgent';
import { Duration } from './Duration';
import { HosEventCodes, mapDriverStatus } from '../../drivers/components/DriversTable';

interface SupportRequestsTableProps {
    isLoading: boolean;
    data: ISupportRequest[];
    pagination: TablePaginationConfig;
    handleTableChange: (pagination: TablePaginationConfig) => void;
}

const driversColumns: ColumnsType<ISupportRequest> = [
    {
        title: 'Name',
        dataIndex: 'requestedByUser',
        key: 'name',
        render: (user, row) => <RequestedByUser user={user} />,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, row) => {
            const driverStatus = mapDriverStatus(
                !!row.driverOnline,
                row.lastHosEvent?.code === HosEventCodes.Driving,
                row.pendingCall
            );
            return <StatusTag status={driverStatus} />;
        },
    },
    {
        title: 'Vehicle ID',
        dataIndex: 'vehicleData',
        key: 'vehicleData',
        render: (vehicleData, row) => {
            return row.driverOnline ? vehicleData?.externalId || 'N/A' : 'N/A';
        },
    },
    {
        title: 'Support ID',
        dataIndex: 'joinCode',
        key: 'joinCode',
    },
    {
        title: 'Request Time',
        dataIndex: 'requestedDate',
        key: 'requestedDate',
        render: (timestamp) => <RequestTime timestamp={timestamp} />,
    },
    {
        title: 'Time In Waiting Room',
        dataIndex: 'requestedDate',
        key: 'timeInWaitingRoom',
        render: (timestamp) => <Duration timestamp={timestamp} />,
    },
    {
        title: 'Time In Call',
        dataIndex: 'callInitiatedDate',
        key: 'timeInCall',
        render: (timestamp) => <Duration timestamp={timestamp} dateRefreshIntervalMs={1000} />,
    },
    {
        title: 'Actions',
        width: 1,
        align: 'right',
        render: (row) => {
            const disabled = !row.driverOnline || row.lastHosEvent?.code === HosEventCodes.Driving;
            return (
                row.status !== SupportRequestStatus.AVAILABLE && (
                    <Space>
                        <SupportAgent supportRequest={row} />
                        <CallButton id={row.id} disabled={disabled} />
                    </Space>
                )
            );
        },
    },
];

export const SupportRequestsTable: React.FC<SupportRequestsTableProps> = ({
    isLoading,
    data,
    pagination,
    handleTableChange,
}) => {
    const handleOnChange = (pagination: TablePaginationConfig) => {
        handleTableChange({
            pageSize: pagination.pageSize,
            current: (pagination.current || 1) - 1,
        });
    };

    return (
        <StyledTableWrapper>
            <Table<ISupportRequest>
                rowKey="id"
                dataSource={data}
                loading={isLoading}
                columns={driversColumns}
                onChange={handleOnChange}
                pagination={{
                    total: pagination.total || 0,
                    pageSize: pagination.pageSize || 10,
                    current: (pagination.current || 0) + 1,
                }}
            />
        </StyledTableWrapper>
    );
};
