import { SearchOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';

import { StatusTagStatus } from '../../../components/UI';

export interface IFormData {
    search: string;
    status?: StatusTagStatus[];
    sortBy?: 'name' | 'address';
}

interface DriversFormProps {
    onChange: (values: IFormData) => void;
}

export const DriversForm: React.FC<DriversFormProps> = ({ onChange }) => (
    <Form layout="inline" initialValues={{ search: '' }} onValuesChange={debounce(onChange, 500)}>
        <Form.Item name="search" noStyle>
            <Input placeholder="Search" prefix={<SearchOutlined />} allowClear />
        </Form.Item>
    </Form>
);
