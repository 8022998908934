import React, { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../store/hooks';
import { selectAccessToken } from '../../store/auth/auth.slice';

const PrivateElement: FunctionComponent<{ children?: any }> = ({ children }) => {
    const location = useLocation();
    const accessToken = useAppSelector(selectAccessToken);

    return accessToken ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateElement;
