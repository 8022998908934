import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../lib/axios-base-query';
import { updateVideoCall } from './communication.slice';
import { AppNames } from '../../types/AppNames';

export enum VideoCallEventType {
    userJoined = 'USER_JOINED',
    userDisconnected = 'USER_DISCONNECTED',
    error = 'ERROR',
}

export const communicationApiSlice = createApi({
    reducerPath: 'communicationApi',
    baseQuery: axiosBaseQuery('communication/'),
    endpoints: (builder) => ({
        getVideoToken: builder.query<any, string>({
            query: (roomName) => ({
                url: `video-token/${roomName}`,
                method: 'get',
            }),
        }),
        getChatToken: builder.mutation<any, null>({
            query: () => ({
                url: 'chat-token',
                method: 'get',
            }),
        }),
        registerForPushNotifications: builder.mutation<any, string>({
            query: (registrationToken) => ({
                url: 'push',
                method: 'post',
                data: {
                    bindingType: 'fcm',
                    address: registrationToken,
                    deviceId: 'webapp',
                },
            }),
        }),
        startVideoCall: builder.mutation<any, { recipientId: string; appName: AppNames }>({
            query: ({ recipientId, appName }) => ({
                url: `video-call/${recipientId}`,
                method: 'get',
                headers: {
                    'x-app-name': appName,
                },
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
                dispatch(updateVideoCall({ roomName: data.roomName }));
            },
        }),
        getVideoCall: builder.query<{ events: { type: string }[] }, string>({
            query: (roomName) => ({
                url: `video-call/room/${roomName}`,
                method: 'get',
            }),
        }),
        getVideoCallStatus: builder.query<{ usersPresent: number }, string>({
            query: (roomName) => ({
                url: `video-call/room/${roomName}/status`,
                method: 'get',
            }),
        }),
        rejectVideoCall: builder.mutation<any, { appName: AppNames; roomName: string }>({
            query: ({ roomName, appName }) => ({
                url: `video-call/room/${roomName}/reject`,
                method: 'get',
                headers: {
                    'x-app-name': appName,
                },
            }),
        }),
        regiserVideoCallEvent: builder.mutation<
            any,
            { roomName: string; eventType: VideoCallEventType; appName: AppNames }
        >({
            query: ({ roomName, eventType, appName }) => ({
                url: `video-call/room/${roomName}/event`,
                method: 'post',
                data: {
                    eventType,
                },
                headers: {
                    'x-app-name': appName,
                },
            }),
        }),
    }),
});

export const {
    useGetVideoTokenQuery,
    useGetVideoCallQuery,
    useGetVideoCallStatusQuery,
    useGetChatTokenMutation,
    useRegisterForPushNotificationsMutation,
    useStartVideoCallMutation,
    useRejectVideoCallMutation,
    useRegiserVideoCallEventMutation,
} = communicationApiSlice;
