import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../lib/axios-base-query';
import { updateVideoCall } from '../communication/communication.slice';
import { AppNames } from '../../types/AppNames';

export enum SupportRequestStatus {
    AVAILABLE = '2_available',
    IN_QUEUE = '1_in_queue',
    IN_PROGRESS = '0_in_progress',
    CLOSED = '3_closed',
}

export enum SupportRequestEventType {
    CLAIMED = 'claimed',
    VIDEO_CALL_INITIATED = 'video_call_initiated',
    CLOSED = 'closed',
    USER_CONNECTED = 'user_connected',
    USER_DISCONNECTED = 'user_disconnected',
}

export enum SupportRequestUserStatus {
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    IN_MOTION = 'in_motion',
}

export interface ISupportRequestEvent {
    type: SupportRequestEventType;
    triggeredByUserId: string;
    timestamp: number;
}

export interface ISupportRequest {
    id: string;
    createdDate: number;
    callInitiatedDate?: number;
    hardwareId: string;
    status: SupportRequestStatus;
    joinCode?: string;
    events: ISupportRequestEvent[];
    requestedByUserId?: string;
    requestedByUser?: {
        firstName: string;
        lastName: string;
    };
    claimedByUserId?: string;
    claimedByUser?: {
        firstName: string;
        lastName: string;
    };
    roomName?: string;
    usersInCall?: number;
    organizationId: string;
    isUserConnected: boolean;
    vehicle?: {
        inMotion: boolean;
    };
    driverOnline?: boolean;
    pendingCall?: boolean;
    lastHosEvent?: {
        type: string;
        code: string;
    };
}

export interface ISupportRequestList {
    supportRequests: ISupportRequest[];
    total: number;
}

export const supportApiSlice = createApi({
    reducerPath: 'supportApi',
    baseQuery: axiosBaseQuery('support/'),
    tagTypes: ['SUPPORT_REQUESTS_LIST'],
    endpoints: (builder) => ({
        searchSupportRequests: builder.query<
            ISupportRequestList,
            { search?: string; pageSize?: number; currentPage?: number }
        >({
            query: (params) => ({
                url: 'request/search',
                method: 'get',
                params,
            }),
            providesTags: ['SUPPORT_REQUESTS_LIST'],
        }),
        claimSupportRequests: builder.mutation<ISupportRequestList, string>({
            query: (requestId: string) => ({
                url: `request/${requestId}/claim`,
                method: 'get',
            }),
            invalidatesTags: ['SUPPORT_REQUESTS_LIST'],
        }),
        closeSupportRequests: builder.mutation<ISupportRequestList, string>({
            query: (requestId: string) => ({
                url: `request/${requestId}/close`,
                method: 'get',
            }),
            invalidatesTags: ['SUPPORT_REQUESTS_LIST'],
        }),
        startVideoCall: builder.mutation<any, { supportRequestId: string; appName: AppNames }>({
            query: ({ appName, supportRequestId }) => ({
                url: `request/${supportRequestId}/call`,
                method: 'get',
                headers: {
                    'x-app-name': appName,
                },
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                const { data } = await queryFulfilled;
                dispatch(updateVideoCall({ roomName: data.roomName, defaultCamera: false, defaultMic: false }));
            },
        }),
    }),
});

export const {
    useLazySearchSupportRequestsQuery,
    useClaimSupportRequestsMutation,
    useCloseSupportRequestsMutation,
    useStartVideoCallMutation,
} = supportApiSlice;
