import { Button, ButtonProps } from 'antd';
import React from 'react';

interface ColoredButtonProps extends ButtonProps {
    colorCodedAction?: 'accept' | 'decline';
}

const colorMap = {
    accept: '#0C8145',
    decline: '#B20707',
};

export const ColoredButton: React.FC<ColoredButtonProps> = ({ colorCodedAction, ...rest }) => {
    if (!colorCodedAction) {
        return <Button {...rest} />;
    }
    return (
        <Button
            {...rest}
            style={{
                ...rest.style,
                backgroundColor: colorMap[colorCodedAction],
                borderColor: colorMap[colorCodedAction],
            }}
        />
    );
};
