import React, { useCallback, useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { FundViewOutlined } from '@ant-design/icons';
import { useStartVideoCallMutation } from '../../../store/support/support-api.slice';
import { AppContext } from '../../../contexts/AppContextProvider';

interface CallButtonProps {
    id: string;
    disabled?: boolean;
}

export const CallButton: React.FC<CallButtonProps> = ({ id, disabled }) => {
    const [startVideoCall, { isLoading }] = useStartVideoCallMutation();
    const { appName } = useContext(AppContext);
    const handleClick = useCallback(() => {
        startVideoCall({ supportRequestId: id, appName });
    }, [id]);

    return (
        <Tooltip title={disabled ? 'Video calling is disabled due to driver status' : ''} placement="topLeft">
            <Button
                icon={<FundViewOutlined />}
                type="primary"
                disabled={disabled}
                onClick={handleClick}
                loading={isLoading}
            />
        </Tooltip>
    );
};
