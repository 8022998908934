import { Tooltip } from 'antd';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import Button from '../../../components/UI/Button/Button';
import { useAppSelector } from '../../../store/hooks';
import {
    ISupportRequest,
    useClaimSupportRequestsMutation,
    useCloseSupportRequestsMutation,
} from '../../../store/support/support-api.slice';
import { selectMe } from '../../../store/users/users.slice';

const StyledButtonClaimed = styled.div`
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
`;

interface SupportAgentProps {
    supportRequest: ISupportRequest;
}

export const SupportAgent: React.FC<SupportAgentProps> = ({ supportRequest }) => {
    const currentUser = useAppSelector(selectMe);
    const [claimSupportRequest, claimSupportRequestResult] = useClaimSupportRequestsMutation();
    const hadleClaim = useCallback(() => {
        claimSupportRequest(supportRequest.id);
    }, [supportRequest.id]);

    const [closeSupportRequest, closeSupportRequestResult] = useCloseSupportRequestsMutation();
    const handleClose = useCallback(() => {
        closeSupportRequest(supportRequest.id);
    }, [supportRequest.id]);

    if (!supportRequest.claimedByUserId) {
        return (
            <Button onClick={hadleClaim} isPending={claimSupportRequestResult.isLoading}>
                Claim
            </Button>
        );
    }

    if (supportRequest.claimedByUserId === currentUser?.id) {
        return (
            <Button type="primary" onClick={handleClose} isPending={closeSupportRequestResult.isLoading}>
                Close
            </Button>
        );
    }

    return (
        <Tooltip
            placement="bottomLeft"
            title={`By: ${supportRequest.claimedByUser?.firstName} ${supportRequest.claimedByUser?.lastName}`}
        >
            <StyledButtonClaimed className="ant-btn ant-btn-default">Claimed</StyledButtonClaimed>
        </Tooltip>
    );
};
