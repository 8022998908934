import { differenceInMinutes, formatDuration, intervalToDuration } from 'date-fns';
import React, { useEffect, useState } from 'react';

interface DurationProps {
    timestamp?: number;
    dateRefreshIntervalMs?: number;
}

const formatDistanceLocale: Record<string, string> = {
    xDays: '{{count}}d',
    xMinutes: '{{count}}m',
    xHours: '{{count}}h',
};

const shortEnLocale: { formatDistance: (token: string, count: string) => string } = {
    formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count),
};

export const Duration: React.FC<DurationProps> = ({ timestamp, dateRefreshIntervalMs }) => {
    const [now, updateNow] = useState(new Date());
    useEffect(() => {
        if (timestamp && !isNaN(timestamp)) {
            const interval = setInterval(() => {
                updateNow(new Date());
            }, dateRefreshIntervalMs || 60000);

            return () => clearInterval(interval);
        }
    }, [timestamp]);

    if (!timestamp || isNaN(timestamp)) {
        return <></>;
    }

    const start = new Date(timestamp * 1000);

    let durationString = '';

    if (differenceInMinutes(now, start) > 0) {
        const duration = intervalToDuration({
            start,
            end: now,
        });

        durationString = formatDuration(duration, { format: ['days', 'hours', 'minutes'], locale: shortEnLocale });
    } else {
        durationString = '0m';
    }

    return <>{durationString}</>;
};
