import { Select, SelectProps } from 'formik-antd';
import styled from 'styled-components';
import React from 'react';

const PadB = styled.b`
    padding-left: 8px;
`;

const StyledSelect = styled(Select)`
    min-width: 100px;
`;

interface FormikSelectWithPrefixProps extends SelectProps {
    prefix?: React.ReactNode;
}

export const FormikSelectWithPrefix: React.FC<FormikSelectWithPrefixProps> = ({ children, prefix, name, ...props }) => {
    return (
        <>
            <div className="ant-select">
                <div className="ant-select-selector">
                    <PadB className="prefix">{prefix}</PadB>
                    <StyledSelect {...props} name={name} bordered={false}>
                        {children}
                    </StyledSelect>
                </div>
            </div>
        </>
    );
};
