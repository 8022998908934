import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../lib/axios-base-query';
import { IUser } from './users.slice';
import { PartnerPlatform } from '../../types/PartnerPlatform';

export interface IUserList {
    users: IUser[];
    total: number;
    dataLimitGB: number;
}

export const usersApiSlice = createApi({
    reducerPath: 'usersApi',
    baseQuery: axiosBaseQuery('organization/'),
    endpoints: (builder) => ({
        getUsers: builder.query<IUser[], void>({
            query: () => ({
                url: `users?partnerPlatform=${PartnerPlatform.PlatformScience}`,
                method: 'get',
            }),
        }),
        searchUsers: builder.query<IUserList, { search?: string; pageSize?: number; currentPage?: number }>({
            query: (params) => ({
                url: `users/search?partnerPlatform=${PartnerPlatform.PlatformScience}`,
                method: 'get',
                params,
            }),
        }),
    }),
});

export const { useGetUsersQuery, useLazySearchUsersQuery } = usersApiSlice;
