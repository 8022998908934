import { Card } from 'antd';
import styled from 'styled-components';

export const FormCard = styled(Card)`
    width: 524px;

    & .ant-card-head-title {
        font-size: 2em;
        font-weight: bold;
    }
`;
