import React, { useState, useEffect, FC } from 'react';
import Video, { Room, RemoteParticipant } from 'twilio-video';
import { Spin } from 'antd';

import { Wrapper } from './TwilioRoom.styles';
import { TwilioVideoChat } from '../TwilioVideoChat/TwilioVideoChat';
import { SpinWrapper } from './TwilioRoom.styles';
import { TwilioClientError, TwilioModal } from '../../models/call.models';
import { useGetVideoCallQuery } from '../../../../store/communication/communication-api.slice';
// import { TwilioMessageChat } from '../TwilioMessageChat/TwilioMessageChat';
// import { QueueTableRow } from '../../../virtual-care/models/virtual-care.models';

interface TwilioRoomProps extends Pick<TwilioModal, 'timerStartedAt'> {
    videoToken: string | null;
    // conversationToken: string | null;
    // profileId: string | undefined;
    roomName: string | undefined;
    showNotification: (message: string) => void;
    handleLogout: () => void;
    // toggleChat: () => void;
    // isMessageChatOpen: boolean | undefined;
    toggleFullscreen: () => void;
    isFullscreen: boolean | undefined;
    // setUnreadMessages: () => void;
    // hasUnreadMessages: boolean | undefined;
    onVideoClientInit: () => void;
    onVideoClientError: (error: TwilioClientError) => void;
    // conversationSid: string | undefined;
    // nextQueueUser: QueueTableRow | undefined;
    timerStartedAt: number | undefined;
    defaultCamera?: boolean;
    defaultMic?: boolean;
}

export const TwilioRoom: FC<TwilioRoomProps> = ({
    roomName,
    videoToken,
    handleLogout,
    toggleFullscreen,
    isFullscreen,
    onVideoClientError,
    onVideoClientInit,
    timerStartedAt,
    // profileId,
    // isMessageChatOpen,
    // conversationToken,
    // conversationSid,
    // setUnreadMessages,
    // hasUnreadMessages,
    // toggleChat,
    // nextQueueUser,
    defaultCamera,
    defaultMic,
    showNotification,
}) => {
    const [room, setRoom] = useState<Room | null>(null);
    const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
    const [pollingInterval, setPollingInterval] = useState<undefined | number>(1500);

    const { data: videoCall } = useGetVideoCallQuery(roomName || '', { pollingInterval });

    useEffect(() => {
        if (videoCall?.events?.some((event) => event.type === 'REJECTED')) {
            showNotification('User declined the call');
            setPollingInterval(undefined);
            handleLogout();
        }
    }, [JSON.stringify(videoCall?.events || {})]);

    useEffect(() => {
        let connectionPromise: any = null;

        const participantConnected = (participant: RemoteParticipant) => {
            setPollingInterval(undefined);
            setParticipants((prevParticipants) => [...prevParticipants, participant]);
        };

        const participantDisconnected = (participant: RemoteParticipant) => {
            setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
            showNotification('User left the call');
            handleLogout();
        };

        if (videoToken && roomName) {
            connectionPromise = Video.connect(videoToken, {
                name: roomName,
            })
                .then((room) => {
                    connectionPromise = null;
                    setRoom(room);
                    room.setMaxListeners(15);

                    room.once('disconnected', () => {
                        handleLogout();
                    });

                    room.on('participantConnected', participantConnected);
                    room.on('participantDisconnected', participantDisconnected);
                    room.participants.forEach(participantConnected);
                    onVideoClientInit();
                })
                .catch((e) => {
                    handleLogout();
                    onVideoClientError({ message: e.message, name: e.name });
                });
        }

        return () => {
            if (connectionPromise) {
                connectionPromise.cancel();
            }
            setRoom((currentRoom) => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        (trackPublication.track as any).stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });

            if (room) {
                room.removeAllListeners();
            }
        };
    }, [roomName, videoToken]);

    return (
        <Wrapper>
            {room ? (
                <>
                    <TwilioVideoChat
                        onLogout={handleLogout}
                        room={room}
                        participants={participants}
                        // toggleChat={toggleChat}
                        // isMessageChatOpen={isMessageChatOpen}
                        isFullscreen={isFullscreen}
                        toggleFullscreen={toggleFullscreen}
                        // hasUnreadMessages={hasUnreadMessages}
                        timerStartedAt={timerStartedAt}
                        defaultCamera={defaultCamera}
                        defaultMic={defaultMic}
                    />
                    {/* <TwilioMessageChat
                        nextQueueUser={nextQueueUser}
                        token={conversationToken}
                        profileId={profileId}
                        roomName={roomName}
                        conversationSid={conversationSid}
                        isMessageChatOpen={isMessageChatOpen}
                        setUnreadMessages={setUnreadMessages}
                    /> */}
                </>
            ) : (
                <SpinWrapper>
                    <Spin size="large" />
                </SpinWrapper>
            )}
        </Wrapper>
    );
};
