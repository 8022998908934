import styled from 'styled-components';
import { Modal } from 'antd';

export const VideoCallModal = styled(Modal)`
    & .ant-modal-body {
        width: 640px;
        height: 480px;
    }

    &.full-screen .ant-modal-body {
        width: 100vw;
        height: 100vh;
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
    }
`;
