import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useGetUserQuery } from '../../../store/users/user-api.slice';

import { CallModal } from './UI/CallModal';
import { ColoredButton } from '../../UI';
import { useAppDispatch } from '../../../store/hooks';
import { updateVideoCall } from '../../../store/communication/communication.slice';
import { useGetVideoCallQuery, useRejectVideoCallMutation } from '../../../store/communication/communication-api.slice';
import { AppContext } from '../../../contexts/AppContextProvider';

interface JoinCallModalProps {
    roomName: string;
    callerId: string;
    close: () => void;
    open: boolean;
    showNotification: (message: string) => void;
}

export const JoinCallModal: React.FC<JoinCallModalProps> = ({ callerId, roomName, close, open, showNotification }) => {
    const dispatch = useAppDispatch();
    const { data: callerData, isLoading: isCallerLoading } = useGetUserQuery(callerId);
    const [pollingInterval, setPollingInterval] = useState<undefined | number>(1500);
    const { data: videoCall } = useGetVideoCallQuery(roomName, { pollingInterval });
    const [rejectVideoCall] = useRejectVideoCallMutation();
    const { appName } = useContext(AppContext);

    const handleJoin = useCallback(() => {
        dispatch(updateVideoCall({ roomName }));
        close();
        setPollingInterval(undefined);
    }, [roomName]);

    const handleDecline = useCallback(() => {
        close();
        rejectVideoCall({ roomName, appName });
        setPollingInterval(undefined);
    }, [roomName]);

    useEffect(() => {
        if (videoCall?.events?.some((event) => event.type === 'USER_DISCONNECTED')) {
            showNotification('User left the call');
            setPollingInterval(undefined);
            close();
        }
    }, [JSON.stringify(videoCall?.events || {})]);

    return (
        <>
            <CallModal
                title="Incoming Call"
                open={open}
                confirmLoading={isCallerLoading}
                onCancel={handleDecline}
                centered
                closable={false}
                footer={[
                    <ColoredButton type="primary" colorCodedAction="decline" key="decline" onClick={handleDecline}>
                        Decline
                    </ColoredButton>,
                    <ColoredButton type="primary" colorCodedAction="accept" key="accept" onClick={handleJoin}>
                        Accept
                    </ColoredButton>,
                ]}
            >
                <p>
                    <Avatar size={84} icon={<UserOutlined />} />
                </p>
                <h2>{`${callerData?.firstName} ${callerData?.lastName}`.trim() || 'Unknown user'}</h2>
                <p>is calling...</p>
            </CallModal>
        </>
    );
};
